.about-container {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 auto;
   font-size: 2.4rem;
   background: var(--jscs-color-background);
   width: 100%;
   padding: 1em 0 0;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 99;
   background: radial-gradient(var(--jscs-color-background) 50%, transparent);
   overflow-x: auto;
}

.title-container {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   min-height: 5em;
}
.about-text-container {
   /* margin: 0 auto 1em; */
   margin: 0 auto;
   box-shadow: 0 15px 30px -15px var(--jscs-color-accent);
   background: var(--jscs-color-background);
}

.image-wrap {
   position: relative;
}

.image-wrap:after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(
      180deg,
      var(--jscs-color-background) 6.25%,
      transparent 25%
   );
}

.image {
   display: inline-block;
   max-width: 100%;
   max-height: 100%;
   min-height: 100%;
}

.image-one {
   /* margin-bottom: -5em; */
   max-width: 100%;
   min-height: 100%;
   min-width: 100%;
}

.sub-title-background {
   position: absolute;
   /* background: linear-gradient(0deg, black 25%, rgba(0, 0, 0, 0.6509803922) 70%, transparent); */
   max-width: -moz-fit-content;
   /* padding: 1em 2em; */
   padding: 1em 0;
   width: 100%;
   /* background: linear-gradient(0deg, var(--jscs-color-background) 12.5%, transparent); */
   min-height: 25%;
   bottom: 0;
   display: flex;
}

.sub-title {
   position: relative;
   max-width: 800px;
   margin: auto;
   font: var(--jscs-font-subheading);
   letter-spacing: var(--jscs-spacing-subheading);
}

.anchor-page-bookmark {
   position: relative;
   top: calc(-100px - var(--jscs-navbar-height));
   width: 0;
   overflow: hidden;
   opacity: 0;
   height: 0;
}

.text-pic-container {
   position: relative;
   display: flex;
}

.text-pic-container-two > * {
   flex-basis: 50%;
}

.text-pic-container-two .text {
   /* text-align: left; */
   position: relative;
}

.text-pic-container-two .image-wrap {
   position: relative;
   max-width: 50%;
   margin-right: -5em;
}

.text-pic-container-two .image-wrap:after {
   background: linear-gradient(
      270deg,
      var(--jscs-color-background) 3.125%,
      transparent 25%
   );
   box-shadow: 0 0 58px 31px var(--jscs-color-background) inset;
}

/* Image & Text Three */
.text-pic-container-three .text {
   z-index: 1;
}

.text-pic-container-three .image-wrap {
   position: relative;
   max-width: 50%;
   margin-left: -5em;
}

.text-pic-container-three .image-wrap:after {
   background: linear-gradient(
      90deg,
      var(--jscs-color-background) 6.25%,
      transparent 25%
   );
   box-shadow: 0 0 58px 31px var(--jscs-color-background) inset;
}

/* Image & Text Four */
.text-pic-container-four {
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.text-pic-container-four .text {
   z-index: 1;
}

.text-pic-container-four .image-wrap {
   position: relative;
   max-width: 100%;
   margin-top: -5em;
}

.text-pic-container-four .image-wrap:after {
   background: linear-gradient(
      180deg,
      var(--jscs-color-background) 6.25%,
      transparent 25%
   );
   box-shadow: 0 0 58px 31px var(--jscs-color-background) inset;
}

p {
   max-width: 800px;
   margin: 1em auto;
}

.text {
   z-index: 1;
   max-height: 100%;
   height: fit-content;
   margin: auto;
   font: var(--jscs-font-body-text);
   padding: 0 1em 1em;
   max-height: calc(100vh - var(--jscs-navbar-height) * 4);
   overflow: auto;
}

.text-one {
   margin-top: 0;
}

@media screen and (max-width: 900px) {
   .bottom-bar {
      flex-direction: column;
   }
   .bottom-bar p {
      font-size: calc(6px + 1vw);
   }
   .login-modal > div > div:last-child {
      display: none;
   }
}
