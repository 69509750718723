.jsCheatSheet-items-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* font-family: Arial; */
  text-align: left;
  font-size: 16px;
  font: var(--jscs-font-body-text);
  min-width: 100%;
  flex-basis: 100%;
  flex-wrap: wrap;
  max-width: 100%;
  background: inherit;
  color: inherit;
  padding: 1em;
  list-style: none;
  padding: 0;

  > .section-anchor {
    position: absolute;
    top: calc(0px - var(--jscs-navbar-height));
    display: block;
  }

  select,
  input,
  textarea,
  a:not([class*="featured-url-arrow"]) {
    // margin-left: 1em;
    text-align: left;
    // white-space: break-spaces;
  }
  li {
    padding: 0.5em 0.25em 0.75em;
  }
}

textarea [class*="sizable-textarea"] {
  padding-right: 2em;
}
select {
  margin-left: -1em;
  max-width: fit-content;
}

select[data-category="priority"] {
  margin: auto;
}

input[type="url"] {
  max-width: 95%;
  text-overflow: ellipsis;
}

input[type*="datetime"] {
  font-size: 12px;
}

.group-jsCheatSheet-goals ul,
.group-jsCheatSheet ul {
  color: white;
  font-weight: bold;
}

////////////////////////////////////////////////////////////////
/// Consistent Color For Like Items Throughout App
////////////////////////////////////////////////////////////////
ul[type="goal"][data-parentmastertype="goal"] {
  background: var(--jscs-color-accent-2-gradient);
}

// Color all Steps
ul[type="step"][data-parentmastertype="step"] {
  background: var(--jscs-color-accent-gradient);
}

ul[type="hold"][data-parentmastertype="hold"] {
  background: var(--jscs-color-background-contrast-dark);
  color: var(--jscs-color-background-warm);
}
////////////////////////////////////////////////////////////////

ul {
  transition: 0.3s all ease;
}

[data-section*="goal"][data-hiddeitems="true"]
  > div
  > [data-maingoal="false"][type="goal"],
ul[data-section="jsCheatSheet"][data-hiddeitems="true"]
  > div
  > ul[data-forreview="true"],
ul[data-section="jsCheatSheet"][data-hiddeitems="true"]
  > div
  > ul[data-markedcomplete="true"] {
  height: 0;
  opacity: 0;
  margin: 0;
  position: absolute;
}

.goal {
  border: 10px solid red !important;
}

ul[data-parentmastertype="goal"] div[data-container-type="collapsibleElm"] {
  display: grid;
  width: 100%;
  grid-gap: 0;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "title title  title  title title title"
    "slug slug slug slug slug slug"
    "msup msup   msup asup asup asup"
    "des   des  des   des des des"
    "type   type  start  start acomp acomp"
    "demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc"
    "demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl"
    "itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes"
    "tags tags tags tags tags tags"
    "markforreview  markforreview markforreview markcomplete  markcomplete markcomplete"
    "createdAt createdAt createdAt updatedAt updatedAt updatedAt"
    "_id _id _id _id _id _id" "id id id id id id" "identifier identifier identifier identifier identifier identifier" "masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
    "buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer"
    "dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container";

  grid-auto-flow: row dense;
  max-width: 100%;
}

.jsCheatSheet-items-group .goal-button-container {
  padding: 0 1em;
  display: flex;
  > button {
    flex-grow: 1;
    color: var(--jscs-color-accent-2);
    margin: auto;
  }
  > button:hover {
    transform: scale(1, 1.05);
    background: var(--jscs-color-accent-2);
    color: var(--jscs-color-background);
    transition: 0.3s all ease;
  }
}

ul[type="goal"] > div[data-container-type="collapsibleElm"] > {
  .list-button-container,
  li[id*="url-item"],
  li[id*="priority-item"],
  li[id*="method-item"],
  li[id*="lectureTime-item"],
  li[id*="labTime-item"],
  li[id*="author-item"],
  li[id*="status-item"],
  li[id*="platform-item"],
  li[id*="method-item"],
  li[class*="featured-url-arrow-wrap"] {
    display: none;
  }
}

ul[type="goal"],
ul[type="step"],
ul[type="hold"] {
  > hs.title {
    display: none;
  }
}

ul[type="goal"],
ul[type="step"],
ul[type="hold"] {
  > div[data-container-type="collapsibleElm"] > li[id*="title-item"] {
    padding-left: 100px;
  }
}

ul[class*="edited-list"]
  div[data-container-type="collapsibleElm"]
  > li[id*="title-item"]
  textarea,
ul[class*="edited-list"]
  div[data-container-type="collapsibleElm"]
  > li[id*="title-item"]
  [class*="sizable-textarea"] {
  border-radius: 11px;
  margin: 1em auto;
  max-height: none;
}

ul[type="step"],
ul[type="hold"] {
  li[id*="progressbar-item"] {
    padding-left: 100px;
    margin-bottom: -0.625em;
    height: 1em;
    padding-top: 0;
    box-shadow: none;
  }
}

ul[type="hold"] {
  li[id*="progressbar-item"] {
    display: none;
  }
  > div[data-container-type="collapsibleElm"] > li[id*="title-item"] {
    transform: translateY(3%);
  }
}

.group-jsCheatSheet-goals > button {
  background: var(--jscs-color-accent-2-gradient);
  border: none;
}

ul[data-parentmastertype="step"] div[data-container-type="collapsibleElm"],
ul[data-parentmastertype="hold"] div[data-container-type="collapsibleElm"] {
  display: grid;
  width: 100%;
  grid-gap: 0;
  grid-template-columns: repeat(6, 1fr) 4em;
  grid-template-areas:
    "progressbar progressbar progressbar progressbar progressbar progressbar progressbar"
    "title  title title  title method   priority url-arrow"
    "slug   slug  slug slug slug slug slug"
    "msup   msup   msup asup asup asup asup"
    "des    des   des   des des des des"
    "url    url   url   url url url url"
    "type   author  author  author platform platform platform"
    "lectureTime  lectureTime  lectureTime labTime  labTime  labTime labTime"
    "start  start    acomp  acomp status  status  status"
    "demonstratedskillsdesc   demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc"
    "demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl"
    "itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes"
    "tags tags tags tags tags tags tags"
    "markforreview  markforreview markforreview markcomplete  markcomplete markcomplete markcomplete"
    "createdAt createdAt createdAt updatedAt updatedAt updatedAt updatedAt"
    "_id _id _id _id _id _id _id"
    "id id id id id id id"
    "identifier identifier identifier identifier identifier identifier identifier "
    "masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
    "buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer"
    "dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container";
  grid-auto-flow: row dense;
  max-width: 100%;
}

ul[data-parentmastertype="step"][class*="edited-list"]
  div[data-container-type="collapsibleElm"],
ul[data-parentmastertype="hold"][class*="edited-list"]
  div[data-container-type="collapsibleElm"] {
  display: grid;
  width: 100%;
  grid-gap: 0;
  grid-template-columns: repeat(6, 1fr) 4em;
  grid-template-areas:
    "title  title title  title title title title"
    "method  method method  method method priority priority "
    "slug   slug  slug slug slug slug slug"
    "msup   msup   msup asup asup asup asup"
    "des    des   des   des des des des"
    "url    url   url   url url url url"
    "type   author  author  author platform platform platform"
    "lectureTime  lectureTime  lectureTime labTime  labTime  labTime labTime"
    "start  start    acomp  acomp status  status  status"
    "demonstratedskillsdesc   demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc"
    "demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl"
    "itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes"
    "tags tags tags tags tags tags tags"
    "markforreview  markforreview markforreview markcomplete  markcomplete markcomplete markcomplete"
    "createdAt createdAt createdAt updatedAt updatedAt updatedAt updatedAt"
    "_id _id _id _id _id _id _id"
    "id id id id id id id"
    "identifier identifier identifier identifier identifier identifier identifier "
    "masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
    "buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer"
    "dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container";
  grid-auto-flow: row dense;
  max-width: 100%;
}

.jsCheatSheet-items-group[type="hold"] {
  padding: 2em;
  box-shadow:
    inset 3px 3px 7px -4px #000000,
    inset -3px -3px 7px -4px white;
  border-radius: 25px;
  background: var(--jscs-color-accent-light);
  color: var(--jscs-color-background-contrast-dark);
  .title-button-container {
    background: inherit;
    > h2,
    > span {
      background: inherit;
    }
  }
  div {
    color: var(--jscs-color-background-contrast-light);
  }
}

.jsCheatSheet-items-group[type="hold"] button {
  background: var(--jscs-color-background-contrast);
  color: var(--jscs-color-accent-light);
  box-shadow:
    inset 5px 5px 9px -5px rgb(255 255 255 / 50%),
    inset -5px -5px 9px -5px hsl(0deg 0% 0% / 50%);
}

.jsCheatSheet-items-group[type="hold"] {
  subtext {
    box-shadow:
      inset 1px 1px 4px -3px #000000,
      inset -1px -1px 4px -3px white;
    padding: 1.5em 2em;
    border-radius: inherit;
  }

  label,
  :before {
    // color: var(--jscs-color-background);
    // background: var(--jscs-color-background-contrast);
    color: inherit;
    background: inherit;
    opacity: 1;
  }
}

.group-title {
  /* background: var(--jscs-color-accent-gradient);
  color: var(--jscs-color-background); */
  background-clip: unset;
  -webkit-text-fill-color: unset;
  padding: 0.5em 1em;
  min-width: 100%;
  text-transform: capitalize;
  margin-bottom: 0;
  font-variant: small-caps;
}

.section-title {
  font-size: 1.25em;
  font-weight: 700;
  width: min-content;
  flex-basis: 100%;
  flex-grow: 1;
  text-transform: uppercase;
  font-family: arial;
  border-left: 37px solid;
  margin-left: 1em;
}

.jsCheatSheet-items-group .title-button-container {
  position: relative;
  display: flex;
  background: var(--jscs-color-background);
  border-radius: 15px;
  overflow: hidden;
  margin: 0 auto 1em;
  width: 100%;
  flex-wrap: wrap;
  display: none;
  .group-title {
    opacity: 1;
    width: auto;
    min-width: min-content;
    margin: auto;
    flex-grow: 0;
  }
  span {
    display: flex;
    padding: 0 0.25em;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    > * {
      flex-basis: fit-content;
    }
  }
  button {
    font-size: 1rem;
    min-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button:first-child:after {
    content: "+";
    display: flex;
    font-size: 2em;
    line-height: 0.5em;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translate(0.16em, -0.16em);
  }
}

.jsCheatSheet-items-group
  .title-button-container[class*="goal"]
  button:first-child {
  background: var(--jscs-color-accent-2);
}

.goals {
  position: relative;
}

.section-subtext-wrap {
  margin: auto;
  flex-grow: 1;
}

.section-subtext-wrap .subtext {
  background: var(--jscs-color-background);
  color: var(--jscs-color-background-contrast-dark);
  padding: 1em;
  margin: auto;
  min-width: calc(100% - 4em);
  font: var(--jscs-font-body-text-12);
  border-radius: 12.5px;
}

.button-container {
  flex-basis: 100%;
  position: relative;
  display: flex;
  justify-content: left;
}

.new-form-button-wrap {
  display: flex;
}

.new-form-button-wrap button {
  width: 80%;
  margin: auto;
}

@keyframes fadeAway {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0px;
  }
}

.fade-away-5 {
  animation: fadeAway 0.3s ease forwards;
  animation-delay: 5s;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 0px;
}

// Goal New Item Forms
[type="goal"] .new-form-button-wrap {
  display: none;
}

////////////////////////////////////////////////////////////////

.jsCheatSheet-history-list-container {
  position: relative;
  max-width: 100%;
  padding: 0 1em;
  ul[type="step"][data-parentmastertype="step"] {
    margin: 0;
  }
}

.jsCheatSheet-history-list-container {
  div {
    max-width: 100%;
    width: 100%;
  }
  > .history-list-inner-wrap {
    position: relative;
    margin: 2em auto;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 1em 0;
    > button {
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      top: 0px;
      flex-grow: 1;
      min-width: min-content;
      min-width: min-content;
      height: 2em;
      max-height: 2em;
      background: var(--jscs-color-background);
      color: var(--jscs-color-accent-dark);
      box-shadow:
        3px 3px 7px -5px hsl(0deg 0% 56% / 50%) inset,
        -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
    }
    button span {
      text-align: center;
    }

    button:hover {
      transform: scale(1, 1.05);
      background: var(--jscs-color-accent);
      color: var(--jscs-color-background);
      transition: 0.3s all ease;
    }
    button:hover span {
      color: var(--jscs-color-background);
    }
    button:active {
      background: var(--jscs-color-accent);
    }
  }
}

body .list-button-container {
  button {
    margin: 0 auto;
    width: 100%;
    background: var(--jscs-color-background);
    color: var(--jscs-color-accent-dark) !important;
  }
  button:hover {
    background: var(--jscs-color-accent-light);
    color: var(--jscs-color-accent-dark) !important;
  }
}

.sort-button-container {
  display: flex;
  align-items: center;
  margin: 1em;
  label {
    padding-left: 1em;
    border-radius: 7px 0 0 7px;
  }
}

.jsCheatSheet-items-group[type="hold"] .sort-button-container {
  background: inherit;
  color: inherit;
  label {
    background: inherit;
    color: inherit;
  }
}

.sort-button-container > * {
  margin: 0 1em 0 0;
  flex-basis: fit-content;
}
.sort-button-container[type="goal"] {
  display: none;
}

.sort-button-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  flex-grow: 1;
}

.sort-button-wrap select {
  text-align-last: left;
  box-shadow:
    inset 1px 1px 4px -3px black,
    inset -1px -1px 3px -2px #b8b8b8;
  width: 100%;
  max-width: 100%;
}

// .sort-button-wrap option { direction: rtl; }
.sort-button-wrap select {
  webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ellipsis;
  border-radius: 6.25px;
  border: none;
  box-shadow:
    inset -1px -1px 3px -2px white,
    inset 1px 1px 3px -2px black;
  transition: 0.7s all ease;
  background: var(--jscs-color-background);
  color: var(--jscs-color-background-contrast);
  font: var(--jscs-font-body-text-12);
  margin-left: 1em;
}

.sort-button-wrap select:hover {
  background: var(--jscs-color-accent-light);
  color: var(--jscs-color-accent-dark);
  font-weight: 700;
  transition: 0.3s all ease;
}

.new-form .cancel-single-form-button {
  position: absolute;
  top: 0;
  right: 0;
  width: min-content;
  background: var(--jscs-color-accent-2-dark);
  color: inherit;
  margin: 0;
  box-shadow: none;
  text-align: center;
  padding: 0;
  padding: 0.125em;
  text-indent: 0.375em;
  border-radius: 0 0 0 12.5px;
}

.new-form .cancel-single-form-button:hover {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--jscs-color-accent-2-light);
  border-radius: 6.25px;
  transform: translate(0, 6.25%) scale(1.125);
}

.no-items-text {
  background: var(--jscs-color-background);
  color: var(--jscs-color-background-contrast);
  padding: 0 1em;
}

.instructions-container,
.no-items-text {
  background: inherit;
  color: inherit;
  font: var(--jscs-font-body-text-12);
  max-width: 800px;
  text-align: left;
  margin: 0 auto;
  width: 100%;
  margin: auto;
  background: var(--jscs-color-background);
  display: flex;
  justify-content: center;
  font: var(--jscs-font-body-text-12);
  box-shadow:
    3px 3px 7px -3px rgba(0, 0, 0, 0.5),
    inset 3px 3px 7px -3px #ffffff;
  border-radius: 6.25px;
  padding: 1em 3% 2em;
  display: flex;
  flex-direction: column;
}

.jsCheatSheet-items-group {
  p,
  ol {
    opacity: 1;
    margin: auto;
  }
}

@media screen and (max-width: 650px) {
  ul[type="goal"] {
    li[id*="progressbar-item"] {
      display: none;
    }
  }
  ul[data-parentmastertype="goal"] div[data-container-type="collapsibleElm"] {
    padding: 0.75em 0;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "title title  title  title title url-arrow"
      "slug slug slug slug slug slug"
      "msup msup   msup msup msup msup"
      "asup asup   asup asup asup asup"
      "des   des  des   des des des"
      "url   url  url   url url url"
      "type   type  type  type type type"
      "author   author  author  author author author"
      "platform   platform  platform  platform platform platform"
      "lectureTime lectureTime lectureTime labTime    labTime    labTime"
      "start start    start start    start  start "
      "acomp acomp    acomp    acomp  acomp acomp"
      "status status    status    status  status status"
      "demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc"
      "demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl"
      "itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes"
      "tags tags tags tags tags tags"
      "markforreview  markforreview markforreview markcomplete  markcomplete markcomplete"
      "createdAt createdAt createdAt updatedAt updatedAt updatedAt"
      "_id _id _id _id _id _id" "id id id id id id" "identifier identifier identifier identifier identifier identifier" "masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
      "buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer"
      "dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container";
    > li {
      // padding: 0 0.25em;
      padding: 0em 0.25em 0.75em;
      text-align: center;
    }
    select {
      max-width: fit-content;
    }
  }

  ul[data-parentmastertype="step"] div[data-container-type="collapsibleElm"],
  ul[data-parentmastertype="hold"] div[data-container-type="collapsibleElm"] {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(6, 1fr) 4em;
    grid-template-areas:
      "progressbar progressbar progressbar progressbar progressbar progressbar progressbar"
      "title  title  title  title title title url-arrow"
      "method   method  method method priority priority priority"
      "slug   slug   slug slug slug slug slug"
      "msup   msup   msup msup msup msup msup"
      "asup   asup   asup asup asup asup asup"
      "des    des   des   des des des des"
      "url    url   url   url url url url"
      "type   type type type type type type"
      "author author author  author  author author author"
      "platform platform platform platform platform platform platform"
      "lectureTime  lectureTime lectureTime labTime    labTime labTime labTime"
      "start   start start  start start start start"
      "acomp   acomp acomp  acomp acomp acomp acomp"
      "status   status status  status status status status"
      "demonstratedskillsdesc   demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc"
      "demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl"
      "itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes"
      "tags tags tags tags tags tags tags"
      "markforreview  markforreview markforreview markcomplete  markcomplete markcomplete markcomplete"
      "createdAt createdAt createdAt updatedAt updatedAt updatedAt updatedAt"
      "_id _id _id _id _id _id _id"
      "id id id id id id id"
      "identifier identifier identifier identifier identifier identifier identifier "
      "masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
      "buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer"
      "dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container";

    > li {
      text-align: center;
    }
    li[class*="method"] {
      text-align: center;
      transform: none;
      min-height: 100%;
      max-height: 100%;
      label {
        display: none;
      }
      input {
        text-align: center;
      }
    }
    select,
    input {
      max-width: fit-content;
      width: 100%;
      text-align: center;
      min-width: min-content;
      margin: auto;
    }

    ::-webkit-input-placeholder,
    ::input-placeholder {
      max-width: fit-content;
      width: 100%;
      text-align: center;
      min-width: min-content;
      margin: auto;
    }

    ul[data-parentmastertype="goal"]
      div[data-container-type="collapsibleElm"]
      li[id*="progressbar-item"] {
      padding: 0em 0.25em 0.75em !important;
    }

    li[class*="method"]:before {
      content: "Method";
      text-align: center;
      margin: 0;
    }
    li[id*="progressbar-item"] {
      padding-left: 110px !important;
    }
    li[class*="priority"] {
      transform: none;
    }

    li[class*="priority"]:before {
      font: inherit;
      font-variant: all-small-caps;
      padding: 0.25vh 1vw;
      margin: 0;
      transform: none;
    }
  }

  ul[data-parentmastertype="goal"] div[data-container-type="collapsibleElm"] {
    li,
    ul[data-parentmastertype="step"] li {
      padding: 0.125em 0 0.75em 0;
    }
    ul[data-parentmastertype="goal"]
      > div[id*="collapsible-elm"]
      > li[id*="title-item"] {
      transform: translateY(-6.25%);
    }
    ul[type="step"]
      > div[data-container-type="collapsibleElm"]
      > li[id*="title-item"] {
      padding-left: 87px;
    }
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }
  body .section-subtext-wrap > button {
    font: var(--jscs-font-body-text);
    font-size: 1em;
    /* font-size: calc(0.5em + 0.2vw); */
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
    letter-spacing: 0.125em;
    font-weight: 700;
  }

  ul[type="goal"],
  ul[type="step"],
  ul[type="hold"] {
    > div[data-container-type="collapsibleElm"] > li[id*="title-item"] {
      padding-left: 90px;
    }
  }
}
