.url-arrow {
  text-decoration: none;
  position: absolute;
  background: var(--jscs-color-accent-dark);
  color: var(--jscs-color-background-warm);
  border-radius: 50px;
  padding: 0.5em;
  right: 01em;
  transform: translateY(-50%);
  top: 45%;
  font-weight: 700;
  height: 45%;
  pointer-events: all;
}

@media screen and (max-width: 650px) {
  .url-arrow {
    right: 0.25em;
  }
}
