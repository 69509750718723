.hours-wrap {
  max-height: none;
  height: auto;
  max-width: 100%;
  box-shadow: none;
  border: none;
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.hours-wrap * {
  margin: 1% 0;
}

.hours-wrap .inner-block {
  max-height: none;
  text-align: center;
  transition: 0.3s all ease;
  transition-timing-function: cubic-bezier(0.81, 1.44, 0, 0.64);
  -webkit-font-smoothing: subpixel-antialiased;
  transform: scale(1) translate3d(0, 0, 0) translateZ(0);
  backface-visibility: hidden;
  // font-size: 0.7em;
  padding-bottom: 0;
  opacity: 0.7;
  flex-basis: 31.33%;
}

// .hours-wrap:after {
//   content: "Stats";
//   text-align: center;
//   display: block;
//   width: 100%;
//   border-bottom: 1px solid #00000017;
//   padding-bottom: 0.5em;
//   text-transform: uppercase;
//   letter-spacing: 0.5em;
//   font-size: 75%;
//   opacity: 0.7;
// }

.hours-wrap .inner-block.reviewhrsadjusted,
.hours-wrap .inner-block.completehrstotal,
.hours-wrap .inner-block.collegecredithours,
.hours-wrap .inner-block.js-version,
.hours-wrap .inner-block.storiestotal,
.hours-wrap .inner-block.lastupdated {
  flex-basis: auto;
  flex-direction: column;
  font-size: 1.25em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  flex-grow: 1;
  margin: auto;

  &:after {
    margin-left: 1em;
  }
}

.hours-wrap .inner-block:hover {
  transform: scale(1.15) translate3d(0, 0, 0) translateZ(0);
  backface-visibility: hidden;
  opacity: 1;
}

.hours-wrap .inner-block.reviewhrsadjusted:hover,
.hours-wrap .inner-block.completehrstotal:hover {
  transform: scale(1.15) translate3d(0, 0, 0) translateZ(0);
  backface-visibility: hidden;
  opacity: 1;
}

.hours-wrap .inner-block:after,
.hours-wrap .js-version:after {
  display: block;
  font-size: 0.6em;
}

.hours-wrap .labhrstotal:after {
  content: "Total Lab Hours";
}

.hours-wrap .lecturehrstotal:after {
  content: "Total Lecture Hours";
}

.hours-wrap .completehrstotal:after {
  content: "Categories";
}

.hours-wrap .reviewhrstotal:after {
  content: "Full Review Hours";
}

.hours-wrap .reviewhrsadjusted:after {
  content: "Review Hours Needed";
}

.hours-wrap .js-version:after {
  content: "JS Version";
  margin-right: 1em;
}

.hours-wrap .totalhours {
  flex-basis: 31.33%;
  flex-grow: 1;
  // background: var(--jscs-color-accent);
  background: var(--jscs-color-accent-2);
  color: var(--jscs-color-background);
  border-radius: 50px;
  padding: 0.5em;
}

.js-version-number {
  color: var(--jscs-color-accent-2);
  color: inherit;
}

.hours-wrap .totalhours:after {
  content: "Total Program Length: ";
}

.hours-wrap .collegecredithours:after {
  content: "Modes";
}
.hours-wrap .storiestotal:after {
  content: "Topics";
}
.hours-wrap .lastupdated:after {
  content: "Last Updated";
}

.news-feed-container {
  min-width: 100%;
  margin-top: 1em;
}
.feed-title {
  color: inherit;
  font-variant: small-caps;
  margin: auto;
  font: var(--jscs-font-subheading);
  letter-spacing: var(--jscs-spacing-subheading);
  font-size: 75%;
}
.feed-link {
  text-decoration: none;
  margin-left: 0.5em;
}
.news-feed-article-container {
  min-width: 100%;
  font-size: 75%;
  display: flex;

  .article-detail {
    margin: auto 1em;
    .article-title {
      font-variant: small-caps;
    }
    .article-publish-date {
      font-size: 75%;
      font-variant: all-small-caps;
    }
  }
}

.rotator-1,
.rotator-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: 2s all ease !important;
}
.rotator-wrapper {
  position: relative;
  margin: 0 auto;
}
.visible-false {
  opacity: 0 !important;
}
.visible-true {
  opacity: 1 !important;
}

@media screen and (max-width: 900px) {
  // .hours-wrap .totalhours {
  //   padding: 1em;
  // }
}

@media screen and (max-width: 550px) {
  .hours-wrap {
    .totalhours {
      flex-basis: 100%;
      padding: 1em;
    }
    .labhrstotal,
    .lecturehrstotal {
      flex-basis: 50%;
    }
  }
}
