.instructions-container {
  position: relative;
  max-width: 32em;
  width: fit-content;
  margin: auto;
  ul {
    // list-style: none;
    text-align: left;
  }
  .content,
  .title {
    background: inherit;
    color: inherit;
  }
}
