.main-title {
   font: var(--jscs--font-heading-2);
   letter-spacing: var(--jscs-spacing-heading);
   position: sticky;
   display: block;
   top: -1.5em;
   background: var(--iq-color-background-warm-gradient);
   padding: 1.5em 0.5em 0.5em;
   /* min-height: calc(30px + 5em); */
   /* max-height: none; */
   border-radius: 50px;
   z-index: 1;
   background: var(--jscs-color-accent-2);
   color: var(--jscs-color-background-warm);
   margin: 0;
   width: 176px;
   z-index: 11;
   box-shadow:
      3px 3px 7px -7px #000000e8,
      7px 7px 10px -7px rgb(0 0 0 / 47%);
   font-weight: 900;
   font-family: "Good Times RG", sans-serif;
   padding: 0.5em;
   margin: 0 auto;
   font-size: 1em;
}

@media only screen and (max-width: 768px) {
   .slide-button-wrap {
      margin: 0 auto;
   }
}

@media only screen and (max-width: 650px) {
   .main-title {
      width: 100%;
   }
   .article-filter {
      width: 100%;
      /* flex-direction: row; */
      /* display: flex; */
   }

   .slide-button-wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-width: 100%;
      position: relative;
      padding: 0;
      align-items: flex-end;
   }

   .slide-button-wrap > * {
      flex-basis: fit-content;
      margin: 0;
      min-width: min-content;
      flex-grow: 1;
      div {
         flex-grow: 0;
         width: fit-content;
         padding: 0 0.125em;
      }
   }
}
