.card-secondary {
  display: flex;
  flex-direction: column;
  color: var(--jscs-color-background);
  background: var(--jscs-color-accent-gradient);
  padding: 2rem;
  box-shadow:
    inset 4px 4px 14px -7px rgb(255 255 255),
    inset -4px -4px 14px -7px rgb(0 0 0 / 50%),
    3px 3px 10px -8px black;
  background-blend-mode: overlay;
  background-size: cover;
  box-shadow:
    inset 0px -13px 7px -14px black,
    inset 0px 14px 7px -14px rgba(0, 0, 0, 0.5294117647);
  margin-top: 2em 0;
}

card-secondary a {
  color: var(--jscs-color-accent);
}
