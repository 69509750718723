.learn-mode-toggle-container {
   position: relative;
   pointer-events: all;
   background: var(--jscs--font-heading-2);
   background: radial-gradient(
      50% 50% at 50% 50%,
      var(--jscs-color-accent-2-light) 0%,
      var(--jscs-color-accent-2) 100%
   );
   border-radius: 50px;
   margin: 1em auto;
   width: 100%;
   max-width: 14em;
   box-shadow:
      3px 3px 7px -4px white inset,
      -3px -3px 7px -4px black inset,
      3px 3px 7px -4px #00000078;
   span[class*="SlideButton_slider"] {
      background-color: var(--jscs-color-accent-2-light);
      box-shadow:
         inset 1px 1px 10px -5px var(--jscs-color-accent-2-dark),
         inset 30px 30px 30px -15px rgba(0, 0, 0, 0.077),
         1px 1px 7px -5px white,
         inset -1px -1px 7px -5px white,
         0 0 2px -2px #ffff006e;
      &:before {
         background-color: var(--jscs-color-accent-2-light);
      }
   }
   * {
      pointer-events: none;
   }

   span[class*="SlideButton_title"] {
      text-transform: uppercase;
      font-weight: 700;
   }
   span[class*="SlideButton_checked-false"] {
      background-color: var(--jscs-color-accent-2);
   }
}

.learn-mode-on.learn-mode-toggle-container {
   background: radial-gradient(
      50% 50% at 50% 50%,
      var(--jscs-color-accent-light) 0%,
      var(--jscs-color-accent) 100%
   );
   span[class*="SlideButton_slider"] {
      background-color: var(--jscs-color-accent-light);
      &:before {
         background-color: var(--jscs-color-accent-light);
      }
   }
}

.big-display-button {
   border: none;
   background: var(--jscs-color-accent-2);
   color: var(--jscs-color-background-cool);
   font: var(--jscs--font-heading-2);
   font-size: 1.4rem;
   padding: 0.25em 1em;
   border-radius: 50px;
   cursor: pointer;
   box-shadow:
      1px 1px 3px -2px black inset,
      -1px -1px 3px -2px white inset;
   &:hover {
      background: var(--jscs-color-accent-2-light);
   }
}

.learn-mode-on.big-display-button {
   background: var(--jscs-color-accent);
}

@media screen and (max-width: 900px) {
   .big-display-button {
      font-size: calc(0.5rem + 0.5vmin);
      height: fit-content;
      max-height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5em auto auto;
   }
}

@media screen and (max-width: 650px) {
   .big-display-button {
      display: none;
   }

   .learn-mode-toggle-container {
      width: 100%;
      .false {
         margin: 1em auto 0;
      }
   }
}
