/****************************************************************
  Color & Font Themes
****************************************************************/

/* Kodchasan font loaded via npm install @fontsource/kodchasan */
/* Covered By Your Grace font loaded via npm install @fontsource/covered-by-your-grace */
@font-face {
  font-family: "Good Times RG";
  src:
    local("Good Times RG"),
    url("./assets/fonts/good_times/good_times_rg.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "DM Mono";
  src:
    local("Good Times RG"),
    url("./assets/fonts/DM_Mono/DMMono-Regular.ttf") format("truetype");
  font-weight: bold;
}
/* @font-face {
  font-family: "Kode Mono";
  src:
    local("Good Times RG"),
    url("./assets/fonts/Kode_Mono/KodeMono-VariableFont_wght.ttf")
      format("truetype");
  font-weight: bold;
} */
/* @font-face {
  font-family: "Roboto Mono";
  src:
    local("Good Times RG"),
    url("./assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf")
      format("truetype");
  font-weight: bold;
} */

/* Orange & Blue - Bright */
/* 
Oranges #00649e  
Blues: rgb(67, 144, 233) hsl(212 79% 97% / 1)
*/

/* --jscs-color-accent: #00649e;
--jscs-color-accent-light: #009bf5;
--jscs-color-accent-dark: #003452;
--jscs-color-accent-2: #9e3f00;
--jscs-color-accent-2-light: #d15400;
--jscs-color-accent-2-dark: #6b2b00;
--jscs-color-background: #ffffff;
--jscs-color-background-light: #ffffff;
--jscs-color-background-dark: #cccccc;
--jscs-color-background-contrast: #0a1e1d;
--jscs-color-background-contrast-light: #63646e;
--jscs-color-background-contrast-dark: #061312;
--jscs-color-black: #0a1e1d;
--jscs-color-white: #ffffff;
--jscs-color-warning: #e7cd79;
--jscs-color-error: #9e0005; */

:root {
  /* 
  Primary Accent: 
      Contrasts background.
      Strong touches, possibly limited, throughout the page. 
  */
  --jscs-color-accent: #00649e;
  --jscs-color-accent-light: #009bf5;
  --jscs-color-accent-dark: #003452;
  --jscs-color-accent-gradient: radial-gradient(
    circle at 10% 20%,
    #003452 0%,
    #009bf5 90%
  );
  /* 
  Secondary Accent:  
      Compliments and possibly contrasts primary accent.
      Adds variety to the page.
  */
  --jscs-color-accent-2: #9e3f00;
  --jscs-color-accent-2-light: #d15400;
  --jscs-color-accent-2-dark: #6b2b00;
  --jscs-color-accent-2-gradient: radial-gradient(
    circle at 10% 20%,
    #6b2b00 0%,
    #d15400 90.1%
  );
  /* 
  Ancillary accents: spot use or with gradients.
  */
  --jscs-color-accent-3: rgb(251, 128, 128);
  --jscs-color-accent-4: rgb(78, 226, 198);

  /* Background */
  --jscs-color-background: #ffffff;
  --jscs-color-background-warm: hsl(19 96% 97% / 1);
  --jscs-color-background-cool: hsl(212 79% 97% / 1);
  --jscs-image-background-texture: url(./assets/media/backgrounds/brushed-alum.png);

  /* 
  Background Contrast: text and anything needing maximum readability
  */
  --jscs-color-background-contrast: #0a1e1d;
  --jscs-color-background-contrast-light: #63646e;
  --jscs-color-background-contrast-dark: #061312;

  /* 
  Universal High Contrast: important alerts, errors, warnings and messages
  */
  --jscs-color-black: #0a1e1d;
  --jscs-color-white: #ffffff;
  --jscs-color-warning: #e7cd79;
  --jscs-color-error: #9e0005;

  /*
  Font Settings
*/
  --jscs--font-heading: normal 700 3.6rem "Good Times RG", sans-serif;
  --jscs--font-heading-2: normal 700 3rem "Good Times RG", sans-serif;
  --jscs-spacing-heading: 0.25em;
  --jscs-spacing-subheading: 0.375em;
  --jscs-spacing-body-text: 0.125em;
  --jscs-font-subheading: normal 700 2rem "Good Times RG", sans-serif;
  --jscs-font-body-text: normal 500 1.6rem "Kodchasan", sans-serif;
  --jscs-font-body-text-12: normal 500 1.2rem "Kodchasan", sans-serif;
}

:root {
  --jscs-navbar-height: 65px;
}

@media screen and (max-width: 900px) {
  :root {
    --jscs--font-heading: normal 700 1.8rem "Good Times RG", sans-serif;
    --jscs--font-heading-2: normal 700 1.6rem "Good Times RG", sans-serif,
      sans-serif;
    --jscs-spacing-heading: 0.125em;
    --jscs-spacing-subheading: 0.162em;
    --jscs-font-subheading: normal 700 1.4rem "Good Times RG", sans-serif,
      sans-serif;
    --jscs-font-body-text: normal 500 1.2rem "Kodchasan", sans-serif;
    --jscs-font-body-text-12: normal 500 0.75rem "Kodchasan", sans-serif;
    --jscs-navbar-height: 40px;
  }
}

/* @media screen and (max-width: 600px) {
  :root {
    --jscs-navbar-height: 50px;
  }
} */

/****************************************************************
  defaults 
****************************************************************/
html {
  font-size: 10px;
  overscroll-behavior: none;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* :focus {
  border: 2px solid var(--jscs-color-accent);
  outline: none;
} */
body {
  background-color: #000000;
  background-color: var(--jscs-color-background);
  /* background-image: var(--jscs-image-background-texture); */
  background-blend-mode: overlay;
  color: hsl(33 100% 96% / 1);
  color: #bb2b95;
  color: var(--jscs-color-accent);
  font:
    normal 500 1.6rem "Kodchasan",
    sans-serif;
  font: var(--jscs-font-body-text);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  font: var(--jscs-font-body-text);
  letter-spacing: var(--jscs-spacing-body-text);
  overscroll-behavior: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  /* background-color: var(--jscs-color-background);
  background-image: var(--jscs-color-accent-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  background-color: var(--jscs-color-background);
  color: var(--jscs-color-background-contrast);
}

p {
  word-break: break-word;
}

a {
  color: var(--jscs-color-accent);
}
code {
  font-family:
    DM Mono,
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
  white-space: pre-wrap;
}

body code[class*="language-"],
pre[class*="language-"] {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
  font-family:
    DM Mono,
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}
code span {
  background: inherit;
  color: inherit;
}

h1 {
  font: var(--jscs--font-heading);
  letter-spacing: var(--jscs-spacing-heading);
}

h2 {
  font: var(--jscs-font-subheading);
  letter-spacing: var(--jscs-spacing-subheading);
}

/* SVG */
#gradient-horizontal {
  --color-stop-1: #ff4024;
  --color-stop-2: #bb2b95;
  --color-stop-3: #bb2b95;
}

#radial-gradient {
  --color-middle: #ff4024;
  --color-fill: #bb2b95;
  --color-edge: #bb2b95;
}
/******/
.section-title {
  font: var(--jscs--font-heading-2);
  letter-spacing: var(--jscs-spacing-heading);
  word-wrap: break-word;
}

.learn-mode {
  display: none !important;
}

.in-learn-mode .learn-mode {
  display: initial !important;
}
