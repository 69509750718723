:global(mark) {
   background: yellow !important;
}

:global(.search-target-mark) {
   border-top: 4px solid var(--jscs-color-accent-light);
   border-bottom: 4px solid var(--jscs-color-accent-light);
   border-radius: 6.25px;
   font-weight: 700;
   display: inline-block;
}

.word-search-container {
   position: relative;
   flex-grow: 1;
}

.search-terms-box {
   position: relative;
   border: none;
   box-shadow:
      1px 1px 3px -2px black inset,
      -1px -1px 3px -2px #b1b1b1 inset;
   font-size: 1.4rem;
   padding: 0.3em 1em;
   transform: translateY(-4%);
   background: var(--jscs-color-accent);
   color: var(--jscs-color-background);
   border-radius: 50px;
   text-align: center;
   font-weight: 700;
   &::placeholder {
      background: inherit;
      color: inherit;
      font-family: "Good Times RG" !important;
      font-style: normal;
      text-align: center;
   }
}

.jump-box-wrap {
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: scale(0.75) translate(-66%, 100%);
   font-size: var(--jscs-font-body-text-12);
   /* opacity: 0.5; */
   color: var(--jscs-color-accent);
   background: var(--jscs-color-background);
   box-shadow: inset 0 -2px 3px -2px black;
   box-shadow:
      0px 3px 7px -5px white inset,
      0px -5px 7px -5px rgba(0, 0, 0, 0.5) inset;
   border-radius: 0 0 50px 50px;
   overflow: hidden;
   padding: 0 1em;
   width: 71%;
   font-size: 1rem;
   font-weight: 700;
   pointer-events: none;
}

.jump-to-box {
   pointer-events: none;
   border: none;
   /* visibility: hidden; */
   position: absolute;
   top: 0;
   left: 0;
   /* max-height: 0; */
   /* max-width: 0; */
   overflow: hidden;
   background: transparent;
   color: inherit;
   display: inline-block;
   position: relative;
   /* width: 3em; */
   /* margin-left: -3em; */
   max-width: 3em;
   padding: 0;
   margin: auto;
   text-align: center;
}

.jump-box-total-amount {
   background: transparent;
}
:global(.hidden-button) {
   max-width: 0 !important;
   padding: 0 !important;
   overflow: hidden !important;
   min-width: 0 !important;
}
@media screen and (max-width: 900px) {
   .word-search-container .search-terms-box,
   .word-search-container button {
      font-size: calc(1rem + 0.5vh) !important;
      padding: 0.5em;
   }
}

@media screen and (max-width: 600px) {
   .word-search-container {
      display: grid;
      grid-area: auto;
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
         "input-box input-box"
         "back-button forward-button";
      margin: auto;
      transform: translateY(0%);
      position: fixed;
      bottom: -1px;
      width: 100%;
      left: 0;
      background: var(--jscs-color-background);
      height: fit-content;
      border-radius: 50px 50px 0 0;
      overflow: hidden;
      max-height: 5.5em;
      button {
         width: 100%;
         border-radius: 0 !important;
         font-size: 24px !important;
         padding: 0 !important;
         font-weight: 700;
         text-indent: 1em;
         box-shadow: none;
         background: var(--jscs-color-accent) !important;
         color: var(--jscs-color-background) !important;
      }
      :global(.hidden-button) {
         height: 0 !important;
      }
   }

   .word-search-container .search-terms-box {
      box-shadow: 0px 8px 15px -10px white inset;
   }

   .word-search-container input {
      grid-area: input-box;
      border-radius: 0;
   }

   .word-search-container button:first-child {
      grid-area: back-button;
      text-align: left;
   }

   .word-search-container button:last-child {
      grid-area: forward-button;
      text-align: right;
   }
   .jump-box-wrap {
      transform: scale(1) translate(-50%, 6%);
      padding: 0.5em 2em 0.5em;
      width: fit-content;
      border-radius: 50px 50px 0 0;
      box-shadow:
         0px 3px 7px -5px #cfcfcf inset,
         0px -5px 7px -5px rgba(0, 0, 0, 0.5) inset;
   }
}
