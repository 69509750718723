/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  min-width: 3em;
  width: 33%;
  height: 1em;
}

/* Hide default HTML checkbox */
.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--jscs-color-accent);
  box-shadow:
    inset 1px 1px 10px -5px black,
    inset 30px 30px 30px -15px rgba(0, 0, 0, 0.077),
    1px 1px 7px -5px white,
    inset -1px -1px 7px -5px white;
  -webkit-transition: 0.4s;
  transition: 0.2s all linear;
}

.slider:before {
  position: absolute;
  content: "";
  min-height: 100%;
  width: 50%;
  right: 0;
  background-color: var(--jscs-color-accent-2);
  box-shadow:
    inset -3px -3px 7px -5px black,
    inset 18px 18px 30px -23px rgb(255 255 255 / 75%),
    1px 1px 7px -5px white,
    inset 4px 4px 7px -5px #ffffff7d,
    2px 2px 7px -5px black;
  transition: 0.2s all linear;
  border-top: 1px solid rgb(0 0 0 / 3%);
}

.checked-false {
  background-color: var(--jscs-color-background);
}

/* input:focus + .slider {
  background-color: var(--jscs-color-accent);
} */

.checked-false:before {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* Rounded sliders */
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: inherit;
}

.slide-button-wrap {
  display: flex;
  margin: 0.5em auto;
  min-width: 9em;
  flex-grow: 1;
  justify-content: center;
  flex-wrap: wrap;
  font: var(--jscs-font-body-text-12);
}

.title::first-letter {
  text-transform: capitalize;
}

.title {
  font: var(--jscs-font-body-text-12);
  background: inherit;
  color: inherit;
  min-width: 100%;
  margin: 0.5em auto 0.25em;
}
