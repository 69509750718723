body .to-top-container {
   position: fixed;
   display: flex;
   bottom: 25%;
   transition: 0.4s all;
   transform-origin: left;
   transition-timing-function: cubic-bezier(0.66, 0.15, 0.28, 2.05);
   z-index: 1;
   max-width: min-content;
   border-radius: 0 50px 50px 0;
   overflow: hidden;
   left: -1em;
   &.hide-button {
      transform: scale(0, 1);
   }
   button {
      border-radius: inherit;
      padding: 1em 2em 1.5em 2em;
   }
}
